import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    titleli: [
      {
        name: '平台列表',
        hash: '#/home'
      }
    ],
  },
  mutations: {
    gaintoken: function (state) {
      state.token = localStorage.getItem("token");
    },
    getli(state,taname){
      state.titleli.forEach(item => {
        if(item.name != taname.name ){
          state.titleli.push(taname)
        }
      })
    }
  },
  actions: {},
  modules: {},
  getters: {
    gettoken: function (state) {
      return state.token;
    },
  },
});
