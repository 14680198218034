<template>
  <div class="nav">
    <div class="name">{{ name }}</div>
    <div class="person">
      <el-row class="block-col-2">
        <el-col :span="12">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">admin</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                ><el-button type="text" class="but" @click="open">
                  退出系统
                </el-button></el-dropdown-item
              >
              <el-dropdown-item
                ><el-button
                  type="text"
                  class="bunamend_password"
                  @click="amendPassword"
                >
                  修改密码
                </el-button></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
      <div class="amend_password">
        <el-dialog
          title="修改密码"
          :visible.sync="isamendPassword"
          width="20%"
          :before-close="amendClose"
        >
          <div class="inputs">
            <div class="inp_newPassword1" @keyup.enter="Password($event)">
              <div class="paname">
                <span class="required">*</span>
                新密码
              </div>
              <el-input
                style="width: 15vw"
                placeholder="请输入新密码"
                v-model="newPassword_1"
                show-password
              ></el-input>
            </div>
            <div class="inp_newPassword2" @keyup.enter="Password($event)">
              <el-input
                style="width: 15vw"
                placeholder="请再次确定密码"
                v-model="newPassword_2"
                show-password
              ></el-input>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="cancelconfirm">取 消</el-button>
            <el-button type="primary" @click="amendconfirm">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/plugins/request";

export default {
  name: "Navtatom",
  props: ["name"],
  data() {
    return {
      isamendPassword: false,
      oldPassword: "",
      newPassword_1: "",
      newPassword_2: "",
      isold: false,
    };
  },
  methods: {
    open() {
      const h = this.$createElement;
      const drthis = this;
      this.$msgbox({
        title: "退出系统",
        message: h("p", null, [h("span", null, "是否退出系统 ")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
                localStorage.clear("token");
                drthis.$store.commit("gaintoken");
                drthis.$router.push("/register");
              }, 300);
            }, 2000);
          } else {
            done();
          }
        },
      })
        .catch(() => {})
        .then(() => {
          this.$message({
            type: "info",
            message: "退出成功",
          });
        });
    },
    //修改密码按钮
    amendPassword() {
      this.isamendPassword = true;
    },
    //修改密码确定按钮
    amendconfirm() {
      this.isamendPassword = false;
      return request({
        url: "admin/refreshPWD",
        method: "post",
        data: {
          token: this.$store.state.token,
          user_password: this.newPassword_1,
          user_password_confirmation: this.newPassword_2,
        },
      }).then((res) => {
        this.$message(res.msg);
        // console.log(res);
      });
    },
    //修改密码取消按钮
    cancelconfirm() {
      this.isamendPassword = false;
      this.oldPassword = "";
      this.newPassword_1 = "";
      this.newPassword_2 = "";
    },
    amendClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
          this.oldPassword = "";
          this.newPassword_1 = "";
          this.newPassword_2 = "";
        })
        .catch(() => {});
    },
    Password(e){
      if(e.keyCode === 13){
        this.amendconfirm();
      }
    }
  },
};
</script>

<style scoped lang="less">
.nav {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid rgb(209, 209, 209);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
  .name {
    height: 40px;
    line-height: 40px;
    width: 150px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
  .person {
    height: 40px;
    line-height: 40px;
    width: 150px;
    margin-right: 30px;
    .block-col-2 {
      width: 150px;
      .el-dropdown-link {
        width: 150px;
        height: 40px;
        text-align: center;
        font-size: 18px;
        cursor: pointer;
        color: #409eff;
        display: inline-block;
      }
    }
    .but {
      height: 40px;
      width: 120px;
      color: black;
      font-size: 18px;
    }
    &:hover {
      background-color: rgb(210, 210, 210);
    }
    .amend_password {
      .inputs {
        div {
          margin-bottom: 10px;
        }
      }
    }
  }
  .required {
    color: red;
  }
  .paname {
    width: 20%;
    height: 30px;
  }
}
</style>
