<template>
    <div class="batch" v-loading="loading">
        <Navtatom :name="name" />
        <div class="taname">
            <h2>{{ name }}</h2>
        </div>
        <div class="main" >
            <div class="conmterd">
                <div class="contopb">
                    <div class="inputs">
                        <div class="ids">
                            <div>平台名称:</div>
                            <input type="text" v-model="ipnname" placeholder="请输入平台名称" />
                            <span class="delete" v-if="ipnname !== ''" @click="ipnname = ''"
                            >×</span
                            >
                        </div>
                        <div class="ids">
                            <div>批次号:</div>
                            <input type="text" v-model="batch" placeholder="请输入批次编号" />
                            <span class="delete" v-if="batch !== ''" @click="batch = ''"
                            >×</span
                            >
                        </div>
                    </div>
                    <div class="bun">
                        <div class="reset" @click="reset">重置</div>
                        <div class="bunInquire" @click="bunInquire">查询</div>
                    </div>
                </div>
            </div>
            <template>
                <div class="tabletop">
                    <div class="tablename">
                        <h3>{{ name }}</h3>
                    </div>
                    <div class="btn">
                        <div class="addtemplate" @click="addtemplate">导入模板</div>
                        <div class="toLead">
                            <el-upload
                            class="upload-demo"
                            action="/"
                            :auto-upload="false"
                            :show-file-list="false"
                            :on-change="toLead"
                            >
                                <el-button class="toLeadbun" type="text">批量导入充电宝数据</el-button>
                            </el-upload>
                        </div>
                        <div class="addlist">
                            <template>
                                <el-button class="add" type="text" @click="addlists">添加批次号</el-button>
                            </template>
                        </div>
                    </div>
                    
                </div>
                <template>
                    <el-table
                    :data="list"
                    style= "width: 100%"
                    :height="fullHeight"
                    @selection-change="selectionChange"
                    :style="{ margin: '0 auto', fontSize: '14px'}"
                    >
                        <el-table-column type="selection" width="55"> </el-table-column>
                        <el-table-column fixed prop="batch_no" label="批次号" width="200">
                        </el-table-column>
                        <el-table-column  prop="belongs_to_platform.name" label="平台名称" width="200">
                        </el-table-column>
                        <el-table-column prop="batch_time" label="出库时间" width="200">
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                            <el-button size="mini" @click="handleEdit(scope.row)"
                                >查看</el-button
                            >
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </template>
            <template>
                <div class="blocks">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage4"
                        :page-sizes="[20, 40, 60, 80]"
                        :page-size="20"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="numsum"
                    >
                    </el-pagination>
                </div>
            </template>
        </div>
        <template>
            <el-dialog
                title="添加批次号"
                :visible.sync="is_batch"
                width="30%"
                :before-close="addbatch"
            >
                <template>
                    <div class="block">
                        <span class="required">*</span>
                        <span class="demonstration">平台名称</span>
                        <el-select v-model="value" placeholder="请选择">
                            <el-option
                            v-for="item in pintlist"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </template>
                <template>
                    <div class="block" :style="{marginTop: '18rpx'}" >
                        <span class="required">*</span>
                        <span class="demonstration">出库时间</span>
                        <el-date-picker
                            v-model="value3"
                            type="datetime"
                            placeholder="选择日期时间"
                            default-time="12:00:00">
                        </el-date-picker>
                    </div>
                </template>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="is_batch = false">取 消</el-button>
                    <el-button type="primary" @click="add_compile">确 定</el-button>
                </span>
            </el-dialog>
        </template>
        <template>
            <el-dialog
                title="提示"
                :visible.sync="is_hint"
                width="30%"
                :before-close="addbatch"
            >
                <div class="tit">{{tit}}</div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="hint_compile">确 定</el-button>
                </span>
            </el-dialog>
        </template>
        <template>
            <el-dialog
                title="充电宝重复、未上报信息"
                :visible.sync="is_loop"
                width="60%"
                :before-close="hint"
            >
                <template>
                    <el-table
                    :data="loop"
                    style= "width: 100%"
                    :style="{ margin: '0 auto', fontSize: '14px'}"
                    >
                        <el-table-column prop="customer_name" label="客户名称" width="130">
                        </el-table-column>
                        <el-table-column  prop="batch_time" label="批次时间" width="200">
                        </el-table-column>
                        <el-table-column fixed prop="power_bank_no" label="充电宝编号" width="200">
                        </el-table-column>
                        <el-table-column prop="charging_qrc" label="机柜二维码base64" width="200">
                        </el-table-column>
                        <el-table-column prop="error_msg" label="错误信息">
                        </el-table-column>
                    </el-table>
                </template>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="setCancel">取 消</el-button>
                    <el-button type="primary" @click="setCompile">确 定</el-button>
                </span>
            </el-dialog>
        </template>
    </div>
</template>

<script>
import Navtatom from "@/components/Navtatom";
import request from "@/plugins/request";

export default{
    name: "Batch",
    components: { Navtatom },
    data(){
        return{
            loading: false,
            name: "批次列表",
            list: [],
            timer: false,
            batch: '',
            ipnname: '',
            fullHeight: document.documentElement.clientHeight - 300,
            numsum: 0,
            limit: 20,
            page: 1,
            currentPage4: 1,
            is_compile: false,
            valstate: '',
            bainid: '',
            pintlist: [],
            redactArr: [],
            is_batch: false,
            batchTime: null,
            is_hint: false,
            tit: null,
            value: '',
            value3: '',
            setBatch: '',
            setPlatform: '',
            loop: [],
            is_loop: false,
            Timeout: null,
        }
    },
    created(){
        this.getBatchList()
        this.getPlatformList()
    },
    methods: {
        getBatchList(){
            this.loading = true
            return request({
                url: "admin/batch/getBatchList",
                method: "post",
                data: {
                    token: this.$store.state.token,
                    platform_name: this.ipnname,
                    batch_no: this.batch,
                    limit: this.limit,
                    page: this.page,
                },
            }).then((res) => {
                this.loading = false
                this.list = res.data.list;
                this.numsum = res.data.count;
                // console.log(res);
            }).catch(err => {
                this.loading = false
                // console.log(err);
            })
        },
        getPlatformList(){
            return request({
                url: "admin/platform/getPlatformList",
                method: "post",
                data: {
                    token: this.$store.state.token,
                },
            }).then((res) => {
                this.pintlist = res.data;
                this.pintlist.forEach(item => {
                    item.time = ''
                });
                // console.log(res);
            })
        },
        loopRequest(){
            return request({
                url: "admin/batch/loopRequest",
                method: "post",
                data: {
                    token: this.$store.state.token,
                    batch_id: this.setBatch,
                    platform_id: this.setPlatform,
                },
            }).then((res) => {
                this.loading = false
                this.loop = res.data;
                // console.log(res);
            }).catch(err => {
                this.loading = false
                console.log(err);
            })
        },
        input(val){
            console.log(val);
        },
        handleSizeChange(val) {
            this.limit = val;
            return request({
                url: "admin/batch/getBatchList",
                method: "post",
                data: {
                token: this.$store.state.token,
                limit: this.limit,
                },
            }).then((res) => {
                this.list = res.data.list;
                this.numsum = res.data.count;
                // console.log(res);
            });
        },
        handleCurrentChange(val) {
            this.page = val;
            return request({
                url: "admin/batch/getBatchList",
                method: "post",
                data: {
                token: this.$store.state.token,
                page: this.page,
                },
            }).then((res) => {
                this.list = res.data.list;
                this.numsum = res.data.count;
                // console.log(res);
            });
        },
        reset() {
            this.ipnname = "";
            this.batch = "";
            this.getBatchList()
        },
        bunInquire() {
            this.getBatchList()
        },
        addlists(){
            this.is_batch = true
        },
        add_compile(){
            if(this.value != '' && this.value3 != ''){
                let year = this.value3.getFullYear(); // 年
                let month = (this.value3.getMonth()+1).toString().length == 1 ? '0'+(this.value3.getMonth()+1):this.value3.getMonth()+1; // 月 这里要加1
                let day = this.value3.getDate().toString().length == 1 ? '0'+ this.value3.getDate().toString():this.value3.getDate().toString(); // 获取几号
                let hour = this.value3.getHours().toString().length == 1? '0'+this.value3.getHours(): this.value3.getHours();//得到小时
                let minu = this.value3.getMinutes().toString().length == 1 ? '0' + this.value3.getMinutes(): this.value3.getMinutes();//得到分钟
                let sec = this.value3.getSeconds().toString().length == 1? '0'+this.value3.getSeconds() : this.value3.getSeconds();//得到秒
                let time = year+'-'+month+'-'+day+' '+ hour+':'+minu+':'+sec;
                // console.log(time);
                return request({
                    url: "admin/batch/addBatch",
                    method: "post",
                    data: {
                        token: this.$store.state.token,
                        platform_id: this.value,
                        batch_time: time,
                    },
                }).then((res) => {
                    this.is_batch = false
                    this.getBatchList()
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });

                    // console.log(res);
                });
            }else{
                this.$message({
                    message: '请选择平台或选择出库时间',
                    type: 'warning'
                });
            }
        },
        
        addbatch(done){
            this.$confirm("确认关闭？")
            .then(() => {
                done();
                // console.log(123);
            }).catch(() => {
                // console.log(456);
            });
        },
        hint_compile(){
            this.is_hint = false
        },
        
        
        addtemplate() {
            window.open(
                "https://device.qugongxiang.vip/download/powerBankList.xlsx?t=" +
                +new Date()
            );
        },
        //选中后触发
        selectionChange(selection) {
            if(selection.length <= 1){
                this.redactArr = selection;
            }else{
                this.$message({
                    message: '只能选择一个批次号!!!',
                    type: 'warning'
                });
                this.redactArr = [];
            }
            // console.log(selection);
        },
        toLead(file){
            if(this.redactArr.length != 0){
                let param = new FormData();
                // console.log(file);
                // console.log(param);
                if (file?.raw) {
                    param.append("file", file.raw, file.name);
                }
                param.append("token", this.$store.state.token);
                param.append('platform_id',this.redactArr[0].platform_id)
                param.append('batch_id', this.redactArr[0].id)
                param.append('batch_time',this.redactArr[0].batch_time)
                request({
                    url: "admin/batch/importData",
                    method: "post",
                    data: param,
                }).then((res) => {
                    this.getBatchList()
                    this.tit = res.msg
                    this.is_hint = true
                });
                return false;

            }else{
                this.$message({
                    message: '请选择一个批次号',
                    type: 'warning'
                });
            }
        },
        handleEdit(row){
            let tiar = this
            this.loading = true
            this.is_loop = true
            this.setBatch = row.id
            this.setPlatform = row.belongs_to_platform.id
            this.Timeout = setInterval(() => {
                if(this.loop.length == 0){
                    tiar.loopRequest()
                }else{
                    clearInterval(this.Timeout)
                }
            },1000)
            // if(this.redactArr.length == 1){
            //     let tiar = this
            //     this.loading = true
            //     this.is_loop = true
            //     this.setBatch = row.id
            //     this.setPlatform = row.belongs_to_platform.id
            //     this.Timeout = setInterval(() => {
            //         tiar.loopRequest()
            //     },1000)
            // }else if(this.redactArr.length < 1){
            //     this.$message({
            //         message: '请选择一个批次号!!!',
            //         type: 'warning'
            //     });
            // }else{
            //     this.$message({
            //         message: '只能选择一个批次号!!!',
            //         type: 'warning'
            //     });
            //     this.redactArr = [];
            // }
        },
        setCancel(){
            this.is_loop = false
            this.loop = []
            clearInterval(this.Timeout)
        },
        setCompile(){
            this.is_loop = false
            this.loop = []
            clearInterval(this.Timeout)
        },
        hint(done){
            this.$confirm("确认关闭？")
            .then(() => {
                done();
                this.loop = []
                clearInterval(this.Timeout)
            }).catch(() => {
                this.loop = []
                clearInterval(this.Timeout)
            });
        },
        
    },
    mounted() {
        const that = this;
        window.onresize = () => {
            return (() => {
                window.fullHeight = document.documentElement.clientHeight;
                that.fullHeight = window.fullHeight;
            })();
        };
    },
    watch: {
        fullHeight(val) {
            if (!this.timer) {
                this.fullHeight = val - 300;
                this.timer = true;
                let that = this;
                setTimeout(function () {
                    that.timer = false;
                }, 0);
            }
        },
    }
}

</script>

<style lang="less">
.batch{
    overflow: hidden;
    flex: 1;
    height: 100vh;
    background-color: rgb(244, 244, 244);
    .taname {
        height: 40px;
        line-height: 40px;
        padding: 0 30px 0 30px;
        font-weight: bold;
        font-size: 18px;
        background-color: rgb(255, 255, 255);
    }
    .main{
        padding: 30px 40px 0 40px;
        .conmterd {
            .contopb {
                height: 60px;
                background-color: rgb(255, 255, 255);
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid rgb(224, 224, 224);
                .inputs {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-left: 30px;
                    font-size: 18px;
                    .ids {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        position: relative;
                        div {
                            margin-right: 10px;
                        }
                        input {
                            width: 21vw;
                            height: 30px;
                            outline: none;
                            padding-left: 8px;
                            border-radius: 2px;
                            padding-right: 30px;
                            border: 1px solid rgb(207, 207, 207);
                            &:hover {
                            border: 1px solid rgb(0, 157, 255);
                            }
                        }
                        &:nth-child(n+3){
                            margin-top: 18px;
                        }
                        &:nth-child(2n-1){
                            margin-right: 10px;
                        }
                    }
                    
                }
                .bun {
                    display: flex;
                    justify-content: space-between;
                    .reset {
                    width: 4vw;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 16px;
                    color: rgb(44, 44, 44);
                    border-radius: 3px;
                    background-color: rgb(255, 255, 255);
                    margin-right: 30px;
                    cursor: pointer;
                    border: 1px solid rgb(223, 223, 223);
                    &:hover {
                        color: rgb(0, 157, 255);
                        border-color: rgb(0, 157, 255);
                        border-radius: 3px;
                    }
                    }
                    .bunInquire {
                    width: 4vw;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 16px;
                    color: white;
                    border-radius: 3px;
                    background-color: rgb(0, 157, 255);
                    margin-right: 30px;
                    cursor: pointer;
                    &:hover {
                        background-color: rgb(0, 94, 255);
                        border-radius: 3px;
                    }
                    }
                }
            }
        }
        .tabletop {
            background-color: white;
            height: 60px;
            padding: 0 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            .tablename {
                width: 100px;
            }
            .btn{
                display: flex;
                align-items: center;
                .addtemplate {
                    background-color: rgb(0, 157, 255);
                    width: 5vw;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 14px;
                    color: white;
                    cursor: pointer;
                    margin-right: 10px;
                    border-radius: 3px;
                    &:hover {
                        background-color: rgb(0, 94, 255);
                    }
                }
                .toLead {
                    margin-right: 10px;
                    .toLeadbun {
                        background-color: rgb(0, 157, 255);
                        width: 7vw;
                        height: 40px;
                        text-align: center;
                        color: white;
                        font-size: 14px;
                        border-radius: 3px;
                        &:hover {
                            background-color: rgb(0, 94, 255);
                        }
                    }
                }
                .addlist {
                    width: 5vw;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    border-radius: 3px;
                    background-color: rgb(0, 157, 255);
                    &:hover {
                        background-color: rgb(0, 94, 255);
                        border-radius: 3px;
                    }

                    .add {
                        width: 5vw;
                        color: white;
                    }
                }
            }
            
        }
        .blocks {
            background-color: white;
        }
        .delete {
            display: inline-block;
            width: 10px;
            height: 10px;
            text-align: center;
            line-height: 11px;
            transform: scale(1.05);
            background-color: rgb(211, 211, 211);
            color: rgb(244, 244, 244);
            border-radius: 50%;
            font-size: 12px;
            cursor: pointer;
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
            &:hover {
                background-color: rgb(179, 179, 179);
            }
        }
    }
    .block{
        margin-bottom: 10px;
        .demonstration{
            margin-right: 20px;
        }
    }
    .required {
        color: red;
    }
}
</style>