import axios from "axios";
import { Message } from "element-ui";
import store from "@/store";
import router from "../router/index";
// create an axios instance
const service = axios.create({
  baseURL: "https://device.qugongxiang.vip", // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    // if (store.getters.token) {
    //   // let each request carry token
    //   // ['X-Token'] is a custom headers key
    //   // please modify it according to the actual situation
    //   config.headers['X-Token'] = getToken()
    // }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    let showMsg = true;
    try {
      const parseData = JSON.parse(response.config.data);
      if (parseData.noShowErrMsg) {
        showMsg = false;
      }
    } catch (err) {
      console.log(err);
    }

    // if the custom code is not 20000, it is judged as an error.
    if (res.code != 100 && res.code != 200 && res.code != 301 && showMsg) {
      Message({
        message: res.msg || "Error",
        type: "error",
        duration: 5 * 1000,
      });

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code == 102) {
        localStorage.removeItem("token");
        store.commit("gaintoken");
        router.push("/Register");
        // to re-login
        // MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        //   confirmButtonText: 'Re-Login',
        //   cancelButtonText: 'Cancel',
        //   type: 'warning'
        // }).then(() => {
        //   store.dispatch('user/resetToken').then(() => {
        //     location.reload()
        //   })
        // })
      }
      if (res.code == 500) {
        Message({
          message: "服务器错误，请联系管理员！",
          type: "error",
          duration: 3 * 1000,
        });
      }
      return Promise.reject(new Error(res.msg || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
