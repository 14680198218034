import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Register from "../views/Register.vue";
import PowerBank from "../views/PowerBank.vue";
import Batch from "../views/Batch.vue";
import { Loading } from "element-ui";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "long",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/powerbank",
    name: "PowerBank",
    component: PowerBank,
  },
  {
    path: "/batch",
    name: "Batch",
    component: Batch,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
