<template>
  <div class="home" v-loading="loading">
    <Navtatom :name="name" />
    <div class="tanames">
      <h2>{{ name }}</h2>
    </div>
    <div class="box-form">
      <div class="conmterd">
        <div class="contopb">
          <div class="inputs">
            <div class="ids">
              <div>平台 ID:</div>
              <input type="text" v-model="inpid" placeholder="请输入ID" />
              <span class="delete" v-if="inpid !== ''" @click="inpid = ''"
                >×</span
              >
            </div>
            <div class="name">
              <div>平台名称:</div>
              <input type="text" v-model="ipnname" placeholder="请输入名称" />
              <span class="delete" v-if="ipnname !== ''" @click="ipnname = ''"
                >×</span
              >
            </div>
          </div>
          <div class="bun">
            <div class="reset" @click="reset">重置</div>
            <div class="bunInquire" @click="bunInquire">查询</div>
          </div>
        </div>
      </div>
      <template>
        <div class="tabletop">
          <div class="tablename">
            <h3>{{ name }}</h3>
          </div>
          <div class="addlist">
            <template>
              <el-button class="add" type="text" @click="addlists"
                >添加平台</el-button
              >
            </template>
          </div>
        </div>
        <el-table
          :data="list"
          style="width: 100%"
          :height="fullHeight"
          :style="{ margin: '0 auto', fontSize: '14px' }"
        >
          <el-table-column prop="name" label="平台名称" width="150">
          </el-table-column>
          <el-table-column prop="created_at" label="创建日期" width="200">
          </el-table-column>
          <el-table-column prop="user_phone" label="联系人电话" width="200">
          </el-table-column>
          <el-table-column
            prop="business_license_number"
            label="营业执照编号"
            width="300"
          >
          </el-table-column>
          <el-table-column prop="user_name" label="平台联系人" width="200">
          </el-table-column>
          <el-table-column prop="platform_domain" label="平台域名" width="200">
          </el-table-column>
          <el-table-column fixed prop="id" label="平台id" width="150">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleEdit(scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <el-dialog
        title="添加平台"
        :visible.sync="dialogAdd"
        width="30%"
        :before-close="handleAdd"
      >
        <div class="addlog">
          <div class="add_name">
            <div class="name"><span class="required">*</span>平台名称</div>
            <div class="inpkey">
              <input
                type="text"
                ref="inpname"
                v-model="addname"
                placeholder="请输入平台名称"
              />
              <span class="delete" v-if="addname !== ''" @click="addname = ''"
                >×</span
              >
            </div>
            <span class="isinput" v-if="isaddname">请输入平台名称</span>
          </div>
          <div class="business_license_number">
            <div class="name">平台营业执照</div>
            <div class="inplicense">
              <input
                type="text"
                v-model="addlicense"
                placeholder="请输入平台营业执照"
              />
              <span
                class="delete"
                v-if="addlicense !== ''"
                @click="addlicense = ''"
                >×</span
              >
            </div>
          </div>
          <div class="user_name">
            <div class="name">平台联系人</div>
            <div class="inpuserName">
              <input
                type="text"
                v-model="adduserName"
                placeholder="请输入平台联系人"
              />
              <span
                class="delete"
                v-if="adduserName !== ''"
                @click="adduserName = ''"
                >×</span
              >
            </div>
          </div>
          <div class="user_phone">
            <div class="name">联系人电话</div>
            <div class="inpphone">
              <input
                type="text"
                v-model="addphone"
                placeholder="请输入联系人电话"
              />
              <span class="delete" v-if="addphone !== ''" @click="addphone = ''"
                >×</span
              >
            </div>
          </div>
          <div class="platform_domain">
            <div class="name">平台域名</div>
            <div class="inpdomain">
              <input
                type="text"
                v-model="adddomain"
                placeholder="请输入平台域名"
              />
              <span
                class="delete"
                v-if="adddomain !== ''"
                @click="adddomain = ''"
                >×</span
              >
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addCancel">取 消</el-button>
          <el-button type="primary" @click="addConfirm">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title="编辑平台"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <div class="inputsbij">
          <div class="device_secret">
            <div class="name">
              <span class="required">*</span>平台&nbsp;&nbsp;&nbsp; id
            </div>
            <div class="inpsecret">
              <input
                type="text"
                ref="inpid"
                v-model="valid"
                placeholder="请输入平台id"
                disabled
              />
            </div>
          </div>
          <div class="product_key">
            <div class="name"><span class="required">*</span>平台名称</div>
            <div class="inpkey">
              <input
                type="text"
                ref="inpname"
                v-model="valname"
                placeholder="请输入平台名称"
              />
              <span class="delete" v-if="valname !== ''" @click="valname = ''"
                >×</span
              >
            </div>
            <span class="isinput" v-if="iskey">请输入平台名称</span>
          </div>
          <div class="business_license_number">
            <div class="name">平台营业执照</div>
            <div class="inplicense">
              <input
                type="text"
                v-model="vallicense"
                placeholder="请输入平台营业执照"
              />
              <span
                class="delete"
                v-if="vallicense !== ''"
                @click="vallicense = ''"
                >×</span
              >
            </div>
          </div>
          <div class="user_name">
            <div class="name">平台联系人</div>
            <div class="inpuserName">
              <input
                type="text"
                v-model="valuserName"
                placeholder="请输入平台联系人"
              />
              <span
                class="delete"
                v-if="valuserName !== ''"
                @click="valuserName = ''"
                >×</span
              >
            </div>
          </div>
          <div class="user_phone">
            <div class="name">联系人电话</div>
            <div class="inpphone">
              <input
                type="text"
                v-model="valphone"
                placeholder="请输入联系人电话"
              />
              <span class="delete" v-if="valphone !== ''" @click="valphone = ''"
                >×</span
              >
            </div>
          </div>
          <div class="platform_domain">
            <div class="name">平台域名</div>
            <div class="inpdomain">
              <input
                type="text"
                v-model="valdomain"
                placeholder="请输入平台域名"
              />
              <span
                class="delete"
                v-if="valdomain !== ''"
                @click="valdomain = ''"
                >×</span
              >
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogCance">取 消</el-button>
          <el-button type="primary" @click="dialogConfirm">确 定</el-button>
        </span>
      </el-dialog>
      <template>
        <div class="blocks">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[20, 40, 60, 80]"
            :page-size="20"
            layout="total, sizes, prev, pager, next, jumper"
            :total="numsum"
          >
          </el-pagination>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import request from "@/plugins/request";
import Navtatom from "@/components/Navtatom";

export default {
  name: "Home",
  components: { Navtatom },
  data() {
    return {
      name: "平台列表",
      list: [],
      inpid: "",
      ipnname: "",
      numsum: 0,
      currentPage4: 1,
      page: 1,
      limit: 20,
      fullHeight: document.documentElement.clientHeight - 300,
      dialogAdd: false,
      isaddname: false,
      addname: "",
      addlicense: "",
      adduserName: "",
      addphone: "",
      adddomain: "",
      dialogVisible: false,
      valid: "",
      valname: "",
      vallicense: "",
      valuserName: "",
      valphone: "",
      valdomain: "",
      itemrow: {},
      iskey: false,
      loading: false,
    };
  },
  created() {
    this.getPlatformList();
  },

  methods: {
    getPlatformList() {
      this.loading = true;
      return request({
        url: "admin/platform/list",
        method: "post",
        data: {
          token: this.$store.state.token,
          id: this.inpid,
          name: this.ipnname,
          limit: this.limit,
          page: this.page,
        },
      }).then((res) => {
        this.list = res.data.list;
        this.numsum = res.data.count;
        this.loading = false;
        // console.log(res);
      }).catch(err => {
        this.loading = false;
      })
    },
    bunInquire() {
      this.getPlatformList();
    },

    handleEdit(row) {
      this.dialogVisible = true;
      this.valid = row.id;
      this.valname = row.name;
      this.vallicense = row.business_license_number;
      this.valuserName = row.user_name;
      this.valphone = row.user_phone;
      this.valdomain = row.platform_domain;
      this.itemrow = row;
      // console.log(row);
    },
    dialogConfirm() {
      if (this.valname !== "") {
        this.dialogVisible = false;
        return request({
          url: "admin/platform/edit",
          method: "post",
          data: {
            token: this.$store.state.token,
            id: this.itemrow.id,
            name: this.valname,
            business_license_number: this.vallicense,
            user_name: this.valuserName,
            user_phone: this.valphone,
            platform_domain: this.valdomain,
          },
        }).then((res) => {
          this.valid = "";
          this.valname = "";
          this.vallicense = "";
          this.valuserName = "";
          this.valphone = "";
          this.valdomain = "";
          this.getPlatformList();
          this.$message("编辑" + res.msg);
          // console.log(res);
        });
      } else if (this.valname === "") {
        this.$refs.inpname.style.border = "1px solid red";
        this.iskey = true;
      }
    },
    dialogCance() {
      this.dialogVisible = false;
      this.iskey = false;
      this.valid = "";
      this.valname = "";
      this.vallicense = "";
      this.valuserName = "";
      this.valphone = "";
      this.valdomain = "";
      this.$refs.inpname.style.border = "1px solid rgb(223, 223, 223)";
    },
    reset() {
      this.ipnname = "";
      this.inpid = "";
      this.getPlatformList();
    },

    addlists() {
      this.dialogAdd = true;
    },

    addConfirm() {
      if (this.addname !== "") {
        this.dialogAdd = false;
        return request({
          url: "admin/platform/add",
          method: "post",
          data: {
            token: this.$store.state.token,
            name: this.addname,
            business_license_number: this.addlicense,
            user_name: this.adduserName,
            user_phone: this.addphone,
            platform_domain: this.adddomain,
          },
        }).then((res) => {
          // console.log(res);
          this.getPlatformList();
          this.addname = "";
          this.addlicense = "";
          this.adduserName = "";
          this.addphone = "";
          this.adddomain = "";
          this.$message("添加" + res.msg);
        });
      } else {
        this.isaddname = true;
      }
    },

    addCancel() {
      this.dialogAdd = false;
      this.addname = "";
      this.addlicense = "";
      this.adduserName = "";
      this.addphone = "";
      this.adddomain = "";
    },

    handleSizeChange(val) {
      this.limit = val;
      return request({
        url: "admin/platform/list",
        method: "post",
        data: {
          token: this.$store.state.token,
          limit: this.limit,
        },
      }).then((res) => {
        this.list = res.data.list;
        this.numsum = res.data.count;
        // console.log(res);
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      return request({
        url: "admin/platform/list",
        method: "post",
        data: {
          token: this.$store.state.token,
          page: this.page,
        },
      }).then((res) => {
        this.list = res.data.list;
        this.numsum = res.data.count;
        // console.log(res);
      });
    },

    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
          this.iskey = false;
          this.$refs.inpname.style.border = "1px solid rgb(223, 223, 223)";
        })
        .catch(() => {});
    },
    handleAdd(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
          this.addname = "";
          this.addlicense = "";
          this.adduserName = "";
          this.addphone = "";
          this.adddomain = "";
        })
        .catch(() => {});
    },
  },

  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        that.fullHeight = window.fullHeight;
      })();
    };
  },
  watch: {
    fullHeight(val) {
      if (!this.timer) {
        this.fullHeight = val - 300;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          that.timer = false;
        }, 0);
      }
    },
    valname() {
      this.iskey = false;
    },
    addname() {
      this.isaddname = false;
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  overflow: hidden;
  flex: 1;
  height: 100vh;
  background-color: rgb(244, 244, 244);
  .tanames {
    height: 40px;
    line-height: 40px;
    padding: 0 30px 0 30px;
    font-weight: bold;
    font-size: 18px;
    background-color: rgb(255, 255, 255);
  }
  .box-form {
    padding: 30px 40px 0 40px;
    .conmterd {
      .contopb {
        height: 60px;
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid rgb(224, 224, 224);
        .inputs {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 30px;
          font-size: 18px;
          .ids {
            margin-right: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 5px;
            position: relative;
            div {
              margin-right: 10px;
            }
            input {
              width: 15vw;
              height: 30px;
              outline: none;
              padding-left: 8px;
              border-radius: 2px;
              padding-right: 30px;
              border: 1px solid rgb(207, 207, 207);
              &:hover {
                border: 1px solid rgb(0, 157, 255);
              }
            }
          }
          .name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            div {
              margin-right: 10px;
            }
            input {
              width: 15vw;
              height: 30px;
              outline: none;
              padding-left: 8px;
              border-radius: 2px;
              padding-right: 30px;
              border: 1px solid rgb(207, 207, 207);
              &:hover {
                border: 1px solid rgb(0, 157, 255);
              }
            }
          }
        }
        .bun {
          display: flex;
          justify-content: space-between;
          .reset {
            width: 4vw;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 16px;
            color: rgb(44, 44, 44);
            border-radius: 3px;
            background-color: rgb(255, 255, 255);
            margin-right: 30px;
            cursor: pointer;
            border: 1px solid rgb(223, 223, 223);
            &:hover {
              color: rgb(0, 157, 255);
              border-color: rgb(0, 157, 255);
              border-radius: 3px;
            }
          }
          .bunInquire {
            width: 4vw;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 16px;
            color: white;
            border-radius: 3px;
            background-color: rgb(0, 157, 255);
            margin-right: 30px;
            cursor: pointer;
            &:hover {
              background-color: rgb(0, 94, 255);
              border-radius: 3px;
            }
          }
        }
      }
    }
    .tabletop {
      background-color: white;
      height: 60px;
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      .tablename {
        width: 100px;
      }
      .addlist {
        width: 5vw;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 3px;
        background-color: rgb(0, 157, 255);
        &:hover {
          background-color: rgb(0, 94, 255);
          border-radius: 3px;
        }

        .add {
          width: 5vw;
          color: white;
        }
      }
    }
    .blocks {
      background-color: white;
    }
    .addlog {
      div {
        font-size: 16px;
        margin-bottom: 20px;
        .name {
          width: 20%;
          height: 30px;
          display: inline-block;
        }
        div {
          position: relative;
          display: inline;
          input {
            width: 250px;
            height: 40px;
            outline: none;
            padding-left: 8px;
            padding-right: 30px;
            margin-left: 10px;
            border-radius: 3px;
            border: 1px solid rgb(223, 223, 223);
            &:hover {
              border: 1px solid rgb(56, 184, 253);
              border-radius: 3px;
            }
          }
        }
      }
    }
    .inputsbij {
      div {
        font-size: 16px;
        margin-bottom: 20px;
        .name {
          width: 20%;
          height: 30px;
          line-height: 30px;
          display: inline-block;
        }
        div {
          position: relative;
          display: inline;
          input {
            width: 250px;
            height: 40px;
            outline: none;
            padding-left: 8px;
            margin-left: 10px;
            border-radius: 3px;
            padding-right: 30px;
            border: 1px solid rgb(223, 223, 223);
            &:hover {
              border: 1px solid rgb(56, 184, 253);
              border-radius: 3px;
            }
          }
        }
      }
    }
  }
  .delete {
    display: inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    line-height: 11px;
    transform: scale(1.05);
    background-color: rgb(211, 211, 211);
    color: rgb(244, 244, 244);
    border-radius: 50%;
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    &:hover {
      background-color: rgb(179, 179, 179);
    }
  }
  .required {
    color: red;
  }
  .isinput {
    color: red;
    font-size: 12px;
    margin-left: 10px;
  }
}
</style>
