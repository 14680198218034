<template>
  <div id="app">
    <div id="nav" v-if="istoken">
      <div class="nav">
        <ul>
          <li>
            <router-link to="/home" @click="addhash('/home')" >平台列表</router-link>
          </li>
          <li>
            <router-link to="/about" @click="addhash('/about')" >设备列表</router-link>
          </li>
          <li>
            <router-link to="/powerbank" @click="addhash('/powerbank')" >充电宝列表</router-link>
          </li>
          <li>
            <router-link to="/batch" @click="addhash('/batch')" >批次列表</router-link>
          </li>
        </ul>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>

export default {
  components: {},
  name: "App",
  props: {},
  data() {
    return {};
  },
  computed: {
    istoken: function () {
      return this.$store.state.token;
    },
  },
  methods: {
    addhash(hash){
      // stthis.$store.commit("getli",);
    }
  },
  created() {
    // localStorage.setItem("token", res.data.token);
    // console.log(location.hash);

    this.$store.commit("gaintoken");
    if (this.istoken) {
      if(location.hash !== "#/"){
        if (location.hash !== "#/"&&location.hash !== "#/home" && location.hash !== "#/about" &&location.hash !== "#/powerbank"&&location.hash !== "#/batch") {
          this.$router.push('/home');
          // console.log(location.hash.match(/.?#(.*)/)[1]);
        }
      }else{
        this.$router.replace('/home');
      }
      
    } else {
      if (this.$route.path !== "/register") {
        this.$router.push("/register");
      }
    }
  },
};
</script>

<style lang="less">
#app {
  width: 100vw;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  display: flex;
  ::-webkit-input-placeholder{
    color: rgb(150, 150, 150);
  }
  :-moz-placeholder{
    color: rgb(150, 150, 150);
  }
  ::-moz-placeholder{
    color: rgb(150, 150, 150);
  }
  :-ms-input-placeholder{
    color: rgb(150, 150, 150);
  }
}

#nav {
  .nav {
    ul {
      list-style: none;
      height: 100vh;
      width: 200px;
      background: #2c3e50;
      li {
        height: 40px;
        font-size: 16px;
        line-height: 40px;
      }
    }
  }
  a {
    width: 200px;
    height: 40px;
    background: #2c3e50;
    color: rgb(198, 198, 198);
    display: inline-block;
    text-decoration: none;
    text-align: center;

    &.router-link-exact-active {
      background: rgb(0, 157, 255);
      color: rgb(255, 255, 255);
    }
    &:hover{
      color: rgb(242, 242, 242);
    }
  }
}
</style>
