<template>
    <div class="powerbank" v-loading="loading">
        <Navtatom :name="name" />
        <div class="taname">
            <h2>{{ name }}</h2>
        </div>
        <div class="main" >
            <div class="conmterd">
                <div class="contopb">
                    <div class="inputs">
                        <div class="ids">
                            <div>客户名称:</div>
                            <input type="text" v-model="ipnname" placeholder="请输入客户名称" />
                            <span class="delete" v-if="ipnname !== ''" @click="ipnname = ''"
                            >×</span
                            >
                        </div>
                        <div class="ids">
                            <div>批次编号:</div>
                            <input type="text" v-model="batch" placeholder="请输入批次编号" />
                            <span class="delete" v-if="batch !== ''" @click="batch = ''"
                            >×</span
                            >
                        </div>
                        <div class="ids">
                            <div>充电宝编号:</div>
                            <input type="text" v-model="serial" placeholder="请输入充电宝编号" />
                            <span class="delete" v-if="serial !== ''" @click="serial = ''"
                            >×</span
                            >
                        </div>
                        <div class="col">
                            <el-form ref="form" :model="form">
                                <el-form-item label="充电宝上报状态:" class="form-item">
                                    <el-select v-model="form.state" placeholder="请选择上报状态">
                                        <el-option v-for="(item,index) in stateArr" :key="index" :label="item" :value="item"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <div class="bun">
                        <div class="reset" @click="reset">重置</div>
                        <div class="bunInquire" @click="bunInquire">查询</div>
                    </div>
                </div>
            </div>
            <template>
                <div class="tabletop">
                    <div class="tablename">
                        <h3>{{ name }}</h3>
                    </div>
                </div>
                <template>
                    <el-table
                    :data="list"
                    style= "width: 100%"
                    :height="fullHeight"
                    :style="{ margin: '0 auto', fontSize: '14px'}"
                    >
                        <el-table-column prop="platform.name" label="客户名称" width="150">
                        </el-table-column>
                        <el-table-column prop="device_id" label="机柜设备编号" width="200">
                        </el-table-column>
                        <el-table-column prop="power_bank_no" label="充电宝编号" width="200">
                        </el-table-column>
                        <el-table-column prop="batch.batch_no" label="批次" width="200">
                        </el-table-column>
                        <el-table-column prop="batch.batch_time" label="出库时间" width="200">
                        </el-table-column>
                        <el-table-column label="上报状态" width="120">
                            <template slot-scope="scope">
                                <el-tag size="medium">
                                    {{scope.row.status === 1 ? "正常" : scope.row.status === 2? "重复":"未上报"}}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column fixed prop="id" label="平台id" width="150">
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                            <el-button size="mini" @click="handleEdit(scope.row)"
                                >编辑</el-button
                            >
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </template>
            <template>
                <div class="blocks">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage4"
                        :page-sizes="[20, 40, 60, 80]"
                        :page-size="20"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="numsum"
                    >
                    </el-pagination>
                </div>
            </template>
        </div>
        <template>
            <el-dialog
                title="编辑充电宝列表"
                :visible.sync="is_compile"
                width="30%"
                :before-close="handleClose"
            >
                <div class="product_state">
                    <div class="name" style="margin-right: 20px">充电宝上报状态</div>
                    <template>
                        <el-select v-model="valstate" placeholder="请选择上报状态">
                        <el-option
                            v-for="(item, index) in stateArr"
                            :key="index"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                        </el-select>
                    </template>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="compile">确 定</el-button>
                </span>
            </el-dialog>
        </template>
    </div>
</template>

<script>
import Navtatom from "@/components/Navtatom";
import request from "@/plugins/request";

export default{
    name: "About",
    components: { Navtatom },
    data(){
        return{
            loading: false,
            name: "充电宝列表",
            list: [],
            timer: false,
            batch: '',
            ipnname: '',
            serial: '',
            fullHeight: document.documentElement.clientHeight - 360,
            numsum: 0,
            limit: 20,
            page: 1,
            currentPage4: 1,
            stateArr: ['未上报','正常','重复'],
            is_compile: false,
            valstate: '',
            bainid: '',
            form: {
                state: '',
            }
        }
    },
    created(){
        this.powerBankList()
    },
    methods: {
        powerBankList() {
            this.loading = true;
            return request({
                url: "admin/powerBank/powerBankList",
                method: "post",
                data: {
                token: this.$store.state.token,
                batch_no: this.batch,
                platform_name: this.ipnname,
                power_bank_no: this.serial,
                status: this.form.state == '未上报' ? 0 : this.form.state == '正常' ? 1 : this.form.state == '重复'? 2:'',
                limit: this.limit,
                page: this.page,
                },
            }).then((res) => {
                this.loading = false;
                this.list = res.data.list;
                this.numsum = res.data.count;
                // console.log(res);
            }).catch(err => {
                this.loading = false;
                // console.log(err);
            })
        },
        reset() {
            this.ipnname = "";
            this.batch = "";
            this.form.state = '',
            this.serial = '',
            this.powerBankList();
        },
        bunInquire() {
            this.powerBankList();
        },

        handleSizeChange(val) {
            this.limit = val;
            return request({
                url: "admin/powerBank/powerBankList",
                method: "post",
                data: {
                token: this.$store.state.token,
                limit: this.limit,
                },
            }).then((res) => {
                this.list = res.data.list;
                this.numsum = res.data.count;
                // console.log(res);
            });
        },
        handleCurrentChange(val) {
            this.page = val;
            return request({
                url: "admin/powerBank/powerBankList",
                method: "post",
                data: {
                token: this.$store.state.token,
                page: this.page,
                },
            }).then((res) => {
                this.list = res.data.list;
                this.numsum = res.data.count;
                // console.log(res);
            });
        },
        handleClose(done) {
            this.$confirm("确认关闭？")
            .then(() => {
                done();
                // console.log(123);
            }).catch(() => {
                // console.log(456);
            });
        },
        handleEdit(row) {
            this.is_compile = true
            this.valstate = row.status == 0 ? '未上报' : row.status == 1 ? '正常' : row.status == 2? '重复':'',
            this.bainid = row.power_bank_no
            console.log(row);
        },
        cancel(){
            this.is_compile = false
        },

        compile(){
            this.is_compile = false
            return request({
                url: "admin/powerBank/editPowerBankId",
                method: "post",
                data: {
                    token: this.$store.state.token,
                    power_bank_no: this.bainid,
                    status: this.valstate == '未上报' ? 0 : this.valstate == '正常' ? 1 : 2,
                },
            }).then((res) => {
                this.powerBankList()
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                // console.log(res);
            });
        }

    },
    mounted() {
        const that = this;
        window.onresize = () => {
            return (() => {
                window.fullHeight = document.documentElement.clientHeight;
                that.fullHeight = window.fullHeight;
            })();
        };
    },
    watch: {
        fullHeight(val) {
            if (!this.timer) {
                this.fullHeight = val - 300;
                this.timer = true;
                let that = this;
                setTimeout(function () {
                    that.timer = false;
                }, 0);
            }
        },
    },
    
}

</script>

<style lang="less">
.powerbank{
    overflow: hidden;
    flex: 1;
    height: 100vh;
    background-color: rgb(244, 244, 244);
    .taname {
        height: 40px;
        line-height: 40px;
        padding: 0 30px 0 30px;
        font-weight: bold;
        font-size: 18px;
        background-color: rgb(255, 255, 255);
    }
    .main{
        padding: 30px 40px 0 40px;
        .conmterd {
            .contopb {
                padding: 20px 0;
                background-color: rgb(255, 255, 255);
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid rgb(224, 224, 224);
                .inputs {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-left: 30px;
                    font-size: 18px;
                    .ids {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        position: relative;
                        div {
                            margin-right: 10px;
                        }
                        input {
                            width: 21vw;
                            height: 30px;
                            outline: none;
                            padding-left: 8px;
                            border-radius: 2px;
                            padding-right: 30px;
                            border: 1px solid rgb(207, 207, 207);
                            &:hover {
                                border: 1px solid rgb(0, 157, 255);
                            }
                        }
                        &:nth-child(n+3){
                            margin-top: 18px;
                        }
                        &:nth-child(2n-1){
                            margin-right: 10px;
                        }
                    }
                    .col{
                        margin-top: 18px;
                        .form-item{
                            margin: 0;
                            display: flex;
                            align-items: center;
                            .el-form-item__label{
                                padding: 0 !important;
                                text-align: left;
                                font-size: 18px;
                                color: #2c3e50;
                                margin-right: 10px;
                            }
                            input{
                                height: 30px !important;
                                width: 21vw;
                                border-radius: 2px;
                                border: 1px solid rgb(207, 207, 207);
                                &:hover {
                                    border: 1px solid rgb(0, 157, 255);
                                }
                            }
                        }
                    }
                    
                }
                .bun {
                    display: flex;
                    justify-content: space-between;
                    .reset {
                    width: 4vw;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 16px;
                    color: rgb(44, 44, 44);
                    border-radius: 3px;
                    background-color: rgb(255, 255, 255);
                    margin-right: 30px;
                    cursor: pointer;
                    border: 1px solid rgb(223, 223, 223);
                    &:hover {
                        color: rgb(0, 157, 255);
                        border-color: rgb(0, 157, 255);
                        border-radius: 3px;
                    }
                    }
                    .bunInquire {
                    width: 4vw;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 16px;
                    color: white;
                    border-radius: 3px;
                    background-color: rgb(0, 157, 255);
                    margin-right: 30px;
                    cursor: pointer;
                    &:hover {
                        background-color: rgb(0, 94, 255);
                        border-radius: 3px;
                    }
                    }
                }
            }
        }
        .tabletop {
            background-color: white;
            height: 60px;
            padding: 0 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            .tablename {
                width: 100px;
            }
            .addlist {
                width: 5vw;
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-radius: 3px;
                background-color: rgb(0, 157, 255);
                &:hover {
                background-color: rgb(0, 94, 255);
                border-radius: 3px;
                }

                .add {
                width: 5vw;
                color: white;
                }
            }
        }
        .blocks {
            background-color: white;
        }
        .delete {
            display: inline-block;
            width: 10px;
            height: 10px;
            text-align: center;
            line-height: 11px;
            transform: scale(1.05);
            background-color: rgb(211, 211, 211);
            color: rgb(244, 244, 244);
            border-radius: 50%;
            font-size: 12px;
            cursor: pointer;
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
            &:hover {
                background-color: rgb(179, 179, 179);
            }
        }
    }
    .product_state {
      font-size: 16px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      .inpkey {
        position: relative;
        display: inline;
        input {
          width: 250px;
          height: 40px;
          outline: none;
          padding-left: 8px;
          padding-right: 30px;
          margin-left: 20px;
          border-radius: 3px;
          border: 1px solid rgb(223, 223, 223);
          &:hover {
            border: 1px solid rgb(56, 184, 253);
          }
        }
      }
    }
}
</style>