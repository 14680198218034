<template>
  <div class="register">
    <div class="register-litn">
      <div class="tlile-name">
        <h1>设备管理系统</h1>
      </div>
      <div class="input">
        <div class="user_name">
          <div v-if="isuser_poss" :style="{ color: 'red',padding: '0 0 5px 5px' }">
            请输入账号和密码
          </div>
          <input
            placeholder="请输入账号"
            v-model="user_namevlu"
            @keyup.enter="enterregister($event)"
            type="text"
          />
        </div>
        <div class="password">
          <div class="passinpicn">
            <input
              placeholder="请输入密码"
              v-model="passwordvlu"
              @keyup.enter="enterregister($event)"
              ref="passval"
              type="password"
            />
            <span class="icon_pass" ref="icon_pass" @click="bunicon"
              ><i class="el-icon-view"></i
            ></span>
          </div>
          <div v-if="ispassword" :style="{ color: 'red',padding: '5px 0 0 5px' }">请输入密码</div>
        </div>
      </div>
      <div class="bun" @click="bunregister">登录</div>
    </div>
  </div>
</template>
<script>
import request from "@/plugins/request";

export default {
  name: "Register",
  props: [],
  data() {
    return {
      user_namevlu: "",
      passwordvlu: "",
      ispassword: false,
      isuser_poss: false,
      isicon: false,
    };
  },

  methods: {
    bunregister: function () {
      if (this.passwordvlu === "" && this.user_namevlu === "") {
        this.isuser_poss = true;
      } else if (this.passwordvlu === "") {
        this.$refs.passval.style.borderColor = "red";
        this.ispassword = true;
        // console.log(this.$refs.passval.style);
      } else {
        let stthis = this;
        return request({
          url: "admin/login",
          method: "post",
          data: {
            user_name: this.user_namevlu,
            password: this.passwordvlu,
          },
        }).then((res) => {
          localStorage.setItem("token", res.data.token);
          stthis.$store.commit("gaintoken");
          this.$router.replace("/home");
          // console.log(res);
        });
      }
    },
    enterregister(e) {
      if (this.passwordvlu === "" && this.user_namevlu === "") {
        this.isuser_poss = true;
      } else if (this.passwordvlu === "") {
        this.$refs.passval.style.borderColor = "red";
        this.ispassword = true;
      } else {
        if (e.keyCode === 13) {
          this.bunregister();
        }
      }
    },
    bunicon() {
      if (this.isicon) {
        this.isicon = false;
        this.$refs.icon_pass.style.color = "rgb(222, 222, 222)";
        this.$refs.passval.type = "password";
      } else {
        this.isicon = true;
        this.$refs.icon_pass.style.color = "rgb(150, 150, 150)";
        this.$refs.passval.type = "text";
      }
    },
  },
  watch: {
    user_namevlu() {
      this.isuser_poss = false;
      this.ispassword = false;
      this.$refs.passval.style.borderColor = "rgb(150, 150, 150)";
    },
    passwordvlu() {
      this.ispassword = false;
      this.$refs.passval.style.borderColor = "rgb(150, 150, 150)";
    },
  },
};
</script>
<style lang="less" scoped>
.register {
  width: 100%;
  height: 100vh;
  position: relative;
  background: url(../assets/login-bg.3125afa7.png) no-repeat center top / 100vw
    100vh;
  .register-litn {
    width: 500px;
    height: 400px;
    background-color: white;
    border: 2px solid rgb(230, 230, 230);
    box-shadow: 0 0 0 9px rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);

    .tlile-name {
      padding: 30px 0;
      text-align: center;
      h1 {
        font-weight: bold;
        font-size: 32px;
      }
    }
    .input {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .user_name {
        margin: 0 auto;
        margin-top: 20px;
        input {
          padding-left: 5px;
          outline: none;
          width: 350px;
          height: 40px;
          font-size: 16px;
          padding-left: 10px;
          border-radius: 2px;
          border: 1px solid rgb(150, 150, 150);
        }
      }
      .password {
        margin: 0 auto;
        margin-top: 20px;
        .passinpicn {
          position: relative;
          input {
            padding-left: 5px;
            outline: none;
            width: 350px;
            height: 40px;
            padding-left: 10px;
            font-size: 16px;
            border-radius: 2px;
            border: 1px solid rgb(150, 150, 150);
          }
          .icon_pass {
            position: absolute;
            top: 50%;
            right: 10px;
            cursor: pointer;
            color: rgb(222, 222, 222);
            transform: translateY(-50%);
          }
        }
      }
    }
    .bun {
      height: 50px;
      width: 300px;
      margin: 0 auto;
      text-align: center;
      line-height: 50px;
      font-size: 18px;
      color: white;
      border-radius: 4px;
      background-color: rgb(56, 56, 255);
      margin-top: 50px;
      cursor: pointer;
    }
  }
}
</style>
